import {
  useFetch,
  useAuth,
  Button,
  Each,
  BottomLink,
  useSearchParams,
  ShiftCard,
  Toggle,
  useStyling,
} from "shiftly-ui";

import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faClock, faUserCheck } from "@fortawesome/pro-regular-svg-icons";
import Illustration from "shiftly-ui/assets/svg/Illustrations/NoShifts.svg";
import { useMemo, useState } from "react";
import SignOn from "./components/SignOn";
import UpcomingShifts from "./components/UpcomingShifts";
import ShiftDetails from "../search/components/shift_details/ShiftDetails";
import styles from "./ShiftsHome.module.css";

const ShiftsHome = () => {
  const styling = useStyling(styles);
  const { user } = useAuth();
  const [showDetails, setShowDetails] = useState(false);
  const [tab, setTab] = useState(0);

  const { data: shifts, isLoading } = useFetch({
    request: {
      entity: "Shift",
      method: "getWithCalculatedFields",
      criteria: {
        status: "confirmed",
        user: user?._id,
      },
      data: { user },
      id: "Shift.GetUpcomingShifts",
    },
    dependency: user?._id,
  });

  const { data: applications } = useFetch({
    request: {
      id: "ShiftApplications.GetCurrentApplications",
      entity: "ShiftApplication",
      method: "get",
      criteria: {
        user: user?._id,
      },
      populate: ["user"],
    },
  });

  const { post: fetchShift } = useFetch({
    options: {
      onSuccess: (data) => setShowDetails(data[0] || false),
    },
  });

  const todayShift = useMemo(() => {
    return shifts?.filter((shift) => new Date(shift.date).toDateString() === new Date().toDateString());
  }, [shifts]);

  const tabs = useMemo(
    () => [
      { text: "Confirmed", icon: <FontAwesomeIcon icon={faUserCheck} /> },
      { text: "Applications", icon: <FontAwesomeIcon icon={faClock} /> },
    ],
    []
  );

  useSearchParams(["shift"], ({ shift: shiftID }) => {
    if (shiftID) {
      fetchShift({
        entity: "Shift",
        method: "getWithCalculatedFields",
        criteria: { _id: shiftID },
      });
    }
  });

  if (isLoading) return <LoadingContainer styling={styling} />;
  return (
    <>
      <h1 className={styling("title")}>Upcoming shifts</h1>
      <Toggle tab={tab} setTab={setTab} tabs={tabs} />
      <ShiftDetails showDetails={showDetails} setShowDetails={setShowDetails} minimised />
      <div className={styling("container")}>
        {tab === 0 && (
          <div className={styling("shifts-section", !isLoading && !shifts.length && "no-shifts")}>
            {shifts.length > 0 ? (
              <>
                {todayShift.length > 0 && (
                  <>
                    <div className={styling("heading-today")}>
                      <h3>Today</h3>
                      <div className={styling("badge")}>{todayShift.length}</div>
                    </div>
                    <Each
                      of={todayShift}
                      render={({ key, ...shift }) => (
                        <SignOn
                          key={key}
                          shift={shift}
                          todayCount={todayShift.length}
                          setShowDetails={setShowDetails}
                        />
                      )}
                    />
                  </>
                )}
                <UpcomingShifts
                  shifts={shifts.filter((s) => !todayShift.map((sh) => sh._id).includes(s._id))}
                  setShowDetails={setShowDetails}
                />
              </>
            ) : (
              <div className={styling("no-shifts")}>
                <div className={styling("no-heading")}>
                  <p>You have no upcoming shifts.</p>
                </div>
                <div className={styling("no-image")}>
                  <img src={Illustration} alt={"No upcoming shifts"} />
                </div>
                <div className={styling("no-button")}>
                  <Button link={"/"} theme={"primary"}>
                    Search for shifts
                  </Button>
                </div>
              </div>
            )}
            <div className={styling("bottom-links")}>
              <BottomLink
                floating={false}
                links={[
                  { name: "Shift History", path: "/shift-history" },
                  { name: "Payments", path: "/payments" },
                  { name: "Trashed Shifts", path: "/trashed-shifts" },
                ]}
              />
            </div>
          </div>
        )}
        {tab === 1 && (
          <div className={styling("applications-section")}>
            <div className={styling("applications-notification")}>
              <p>
                Expired applications or shifts filled by other workers will automatically be removed from your
                applications tray.
              </p>
            </div>
            {applications.length > 0 ? (
              <div className={styling("applications")}>
                <Each
                  of={applications}
                  render={(application, index) => {
                    return <ShiftCard key={index} shift={application?.shift} setShowDetails={setShowDetails} />;
                  }}
                />
              </div>
            ) : (
              <div className={styling("no-applications")}></div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ShiftsHome;

const LoadingContainer = ({ styling }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={styling("loading-container")}
    >
      <div className={styling("loading-card")}>
        <div className={styling("loading-spinner")}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      </div>
    </motion.div>
  );
};
