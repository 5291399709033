import { useFetch, roundNumber, useAuth } from "shiftly-ui";

import styles from "./ShiftDetails.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faPlus } from "@fortawesome/pro-duotone-svg-icons";

const ShiftDetailsMoreShifts = ({ location, setShowDetails }) => {
  const { user } = useAuth();

  const { data: shifts } = useFetch({
    request: {
      entity: "Shift",
      method: "getWithCalculatedFields",
      criteria: {
        location: location?._id,
        status: "published",
      },
      data: {
        user,
      },
    },
    dependency: location?._id,
  });

  return shifts?.length ? (
    <div className={styles["more-shifts-container"]}>
      <h4>Other shifts with this employer</h4>
      <div className={styles["more-shifts"]}>
        {shifts.map((shift) => {
          return (
            <ShiftCard
              {...shift}
              key={shift._id}
              setShowDetails={setShowDetails}
            />
          );
        })}
      </div>
    </div>
  ) : (
    <></>
  );
};

const ShiftCard = ({ setShowDetails, ...props }) => {
  const { position, hourlyRate, prettyDate, shiftPeriod, modifier } = props;

  return (
    <div
      className={styles["shiftcard-container"]}
      onClick={() => setShowDetails(props)}
    >
      <div className={styles["shiftcard-wage"]}>
        <p>${roundNumber(hourlyRate + modifier)}</p>
      </div>
      <FontAwesomeIcon icon={faPlus} className={styles["shiftcard-plus"]} />
      <div className={styles["shiftcard-details"]}>
        <h4 className={styles["shiftcard-position"]}>{position?.name}</h4>
        <p>
          {prettyDate}
          <span>{shiftPeriod}</span>
        </p>
      </div>
    </div>
  );
};

export default ShiftDetailsMoreShifts;
