import { useUI } from "shiftly-ui";

import { useCallback } from "react";

import { useSpring } from "@react-spring/web";

import { useDrag } from "react-use-gesture";

const useSlideOverlaySwipe = () => {
  const { updateSetting } = useUI();

  const [swiperOverlay, swipeApi] = useSpring(
    () => ({
      from: { y: 200 },
      to: { y: 200 },
    }),
    [],
  );

  const hideOverlay = useCallback(
    (config) => {
      updateSetting({ setting: "new_user", value: false });

      swipeApi.start({
        y: 200,
        config: {
          duration: 200,
          ...config,
        },
      });
    },
    [swipeApi, updateSetting],
  );

  const showOverlay = useCallback(
    (config = {}) => {
      swipeApi.start({
        y: 0,
        config: {
          duration: 500,
          ...config,
        },
      });
    },
    [swipeApi],
  );

  const bindSwipe = useDrag(
    ({ velocity, down: dragging, movement: [dx, dy] }) => {
      const trigger = velocity > 0.3;

      const config = { friction: 50, tension: dragging ? 800 : 500 };

      if (dragging) {
        swipeApi.start({
          y: dy > 0 ? dy : 0,
          config,
        });

        return;
      }

      if (!trigger) {
        swipeApi.start({
          y: 0,
          config,
        });

        return;
      }

      hideOverlay(config);
    },
  );

  return { swiperOverlay, bindSwipe, hideOverlay, showOverlay };
};

export default useSlideOverlaySwipe;
