import styles from "./ShiftDetails.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronLeft, faStar } from "@fortawesome/pro-light-svg-icons";

const ShiftDetailsHeader = ({
  location,
  logo,
  setShowDetails,
  confirmShift,
  setConfirmShift,
}) => {
  return (
    <>
      <div className={styles["logo-container"]}>
        <img src={logo} alt="Business Logo" />
      </div>
      <div
        className={styles["header-container"]}
        onClick={() => {
          if (confirmShift) {
            setConfirmShift(false);

            return;
          }

          setShowDetails(false);
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
        <div className={styles["rating-container"]}>
          <FontAwesomeIcon icon={faStar} />
          <p>{location?.rating?.toFixed(2) || 5}</p>
        </div>
      </div>
    </>
  );
};

export default ShiftDetailsHeader;
