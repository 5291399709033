import React from "react";

import ReactDOM from "react-dom/client";

import App from "./App";

import { Provider } from "react-redux";

import { BrowserRouter } from "react-router-dom";

import store from "./redux/store";

import { ReactQueryDevtools } from "react-query/devtools";

import { QueryClient, QueryClientProvider } from "react-query";

import { CopilotKit } from "@copilotkit/react-core";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/sw.js")
    .then((registration) => {
      console.log("Service Worker registered with scope:" + registration.scope);
    })
    .catch((error) => {
      console.log("Service Worker registration failed:", error);
    });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <CopilotKit
    runtimeUrl={process.env.REACT_APP_API_URL + "copilotkit"}
    showDevConsole={false}
  >
    <Provider store={store}>
      <QueryClientProvider client={new QueryClient()}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} position={"bottom-left"} />
      </QueryClientProvider>
    </Provider>
  </CopilotKit>,
);
