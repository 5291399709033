import { useStyling, Swiper, useFetch, Overlay } from "shiftly-ui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./MessagesMenu.module.css";

import { faTimes } from "@fortawesome/pro-solid-svg-icons";

import ShiftCard from "src/pages/shifts/components/ShiftCard";

const MessagesMenu = ({ location, open, setOpen, callback = () => {} }) => {
  const styling = useStyling(styles);

  const { data: upcomingShifts } = useFetch({
    request: {
      entity: "Shift",
      method: "get",
      populate: [
        "position",
        {
          path: "location",
          populate: ["address"],
        },
      ],
      criteria: {
        status: {
          $nin: ["cancelled", "expired", "draft", "completed"],
        },
        location: location?._id,
      },
    },
  });

  return (
    <>
      <Overlay open={open} setOpen={setOpen} />
      <div className={styling("container", open && "open")}>
        <div className={styling("heading")} onClick={() => setOpen(false)}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className={styling("title")}>
          <h4>Upcoming Shifts</h4>
          <p>Select a shift to reference:</p>
        </div>
        <div className={styling("shifts")}>
          <Swiper
            slides={upcomingShifts.map((slide, index) => (
              <div
                className={styling("slide")}
                key={index}
                onClick={() => {
                  callback(slide);

                  setOpen(false);
                }}
              >
                <ShiftCard
                  shift={{ ...slide, address: slide.location?.address }}
                />
              </div>
            ))}
          ></Swiper>
        </div>
      </div>
    </>
  );
};

export default MessagesMenu;
