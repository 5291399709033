import { CentralHeader, useStyling, useSearchParams } from "shiftly-ui";

import styles from "./EmploymentDetails.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";

import EDPersonalDetails from "./components/EDPersonalDetails";

import EDContactDetails from "./components/EDContactDetails";

import EDBankDetails from "./components/EDBankDetails";

import EDTaxDetails from "./components/EDTaxDetails";

import EDSuperDetails from "./components/EDSuperDetails";

import { useState, cloneElement, useRef } from "react";

import useOnboardingComplete from "src/hooks/useOnboardingComplete";

const EmploymentDetails = () => {
  const styling = useStyling(styles);

  const { todo } = useOnboardingComplete();

  return (
    <div className={styling("container")}>
      <CentralHeader text={"Employment Details"} chevron link={"/profile"} />
      <div className={styling("content")}>
        <Link
          text={"Personal Details"}
          badge={!todo["personalDetails"]}
          activeKey={"personalDetails"}
        >
          <EDPersonalDetails />
        </Link>
        <Link
          text={"Contact Details"}
          badge={!todo["contactDetails"]}
          activeKey={"contactDetails"}
        >
          <EDContactDetails />
        </Link>
        <Link
          text={"Bank Details"}
          badge={!todo["bankDetails"]}
          activeKey={"bankDetails"}
        >
          <EDBankDetails />
        </Link>
        <Link
          text={"Tax Details"}
          badge={!todo["taxDetails"]}
          activeKey={"taxDetails"}
        >
          <EDTaxDetails />
        </Link>
        <Link
          text={"Super Details"}
          badge={!todo["superDetails"]}
          activeKey={"superDetails"}
        >
          <EDSuperDetails />
        </Link>
      </div>
    </div>
  );
};

const Link = ({ text, badge, children, activeKey }) => {
  const [show, setShow] = useState(false);

  const styling = useStyling(styles);

  const directedRef = useRef();

  useSearchParams(["key"], ({ key }) => {
    if (key === activeKey && !directedRef.current) {
      directedRef.current = true;

      setShow(true);
    }
  });

  return (
    <>
      <div className={styling("link-container")} onClick={() => setShow(true)}>
        <div className={styling("link-text")}>{text}</div>
        {badge && <div className={styling("link-badge")}>incomplete</div>}
        <FontAwesomeIcon
          icon={faChevronRight}
          className={`${styling("chevron")} ${show ? styling("chevron-open") : ""}`}
        />
      </div>
      {cloneElement(children, { show, setShow })}
    </>
  );
};

export default EmploymentDetails;
