import {
  useUI,
  usePushNotifications,
  useFetch,
  useAuth,
  useUserActivity,
} from "shiftly-ui";

import styles from "./ShifterNavigation.module.css";

import { useLocation, useNavigate } from "react-router-dom";

import clsx from "clsx";

import { useEffect, useMemo, useState } from "react";

import useMessagesCriteria from "src/pages/inbox/hooks/useMessagesCriteria";

const ShifterNavigation = () => {
  const { excludedPaths } = useUI();

  const [visible, setVisible] = useState(true);

  let location = useLocation();

  const { user } = useAuth();

  const { unreadNotifications } = usePushNotifications(user?._id);

  const criteria = useMessagesCriteria();

  const isUserActive = useUserActivity();

  const { data: messages } = useFetch({
    request: {
      entity: "Message",
      method: "getMessagesWithAccess",
      data: { ...criteria },
      id: "Message.GetMessagesWithAccess",
    },
    options: {
      enabled: Boolean(user?._id),
      select: (data) =>
        data.filter(
          (message) => message.unread && message.for_id === user?._id,
        ),
    },
    dependency: isUserActive,
  });

  const navItems = useMemo(
    () => [
      {
        label: "Search",
        link: "/",
        icon: (
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15 5C9.47715 5 5 9.47715 5 15C5 20.5228 9.47715 25 15 25C20.5228 25 25 20.5228 25 15C25 9.47715 20.5228 5 15 5ZM2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5C8.09644 27.5 2.5 21.9036 2.5 15ZM20.8839 9.11612C21.1886 9.42087 21.3149 9.86112 21.218 10.2811L19.343 18.4061C19.2355 18.8718 18.8718 19.2355 18.4061 19.343L10.2811 21.218C9.86112 21.3149 9.42087 21.1886 9.11612 20.8839C8.81136 20.5791 8.6851 20.1389 8.78201 19.7189L10.657 11.5939C10.7645 11.1282 11.1282 10.7645 11.5939 10.657L19.7189 8.78201C20.1389 8.6851 20.5791 8.81136 20.8839 9.11612ZM12.9173 12.9173L11.6677 18.3323L17.0827 17.0827L18.3323 11.6677L12.9173 12.9173ZM13.75 15C13.75 14.3096 14.3096 13.75 15 13.75H15.0125C15.7029 13.75 16.2625 14.3096 16.2625 15C16.2625 15.6904 15.7029 16.25 15.0125 16.25H15C14.3096 16.25 13.75 15.6904 13.75 15Z"
              fill="#60728B"
            />
          </svg>
        ),
        activeKey: "",
      },
      {
        label: "Saved",
        link: "/saved",
        icon: (
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.9992 5.73851C12.3453 3.4704 8.43097 2.9049 5.36353 5.5175C2.04192 8.34657 1.55684 13.117 4.1864 16.49C5.175 17.7581 7.09808 19.6562 8.93821 21.3875C10.8024 23.1415 12.6619 24.7986 13.5789 25.6081C13.5847 25.6133 13.5908 25.6186 13.5969 25.6241C13.6823 25.6995 13.7932 25.7976 13.9003 25.8779C14.0299 25.9752 14.22 26.0991 14.4752 26.175C14.8116 26.275 15.173 26.275 15.5094 26.175C15.7646 26.0991 15.9547 25.9752 16.0843 25.8779C16.1914 25.7976 16.3023 25.6995 16.3877 25.6241L16.4057 25.6081C17.3227 24.7986 19.1822 23.1415 21.0464 21.3875C22.8865 19.6562 24.8096 17.7581 25.7982 16.49C28.4167 13.1312 28.0078 8.32336 24.6081 5.50656C21.5074 2.93764 17.6468 3.46999 14.9992 5.73851ZM14.0514 8.31419C12.1441 6.09152 9.16231 5.56589 6.98455 7.42073C4.66979 9.39226 4.36135 12.6482 6.15805 14.9529C7.00953 16.0451 8.79083 17.8162 10.6513 19.5668C12.3376 21.1533 14.0245 22.6636 14.9923 23.5208C15.9601 22.6636 17.647 21.1533 19.3333 19.5668C21.1938 17.8162 22.9751 16.0451 23.8265 14.9529C25.6343 12.634 25.3465 9.36497 23.0131 7.43167C20.7751 5.57743 17.8468 6.10209 15.9486 8.31419C15.7112 8.59094 15.3647 8.75019 15 8.75019C14.6353 8.75019 14.2888 8.59094 14.0514 8.31419Z"
              fill="#60728B"
            />
          </svg>
        ),
        activeKey: "saved",
      },
      {
        label: "Shifts",
        link: "/shifts",
        icon: (
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15 5C9.47715 5 5 9.47715 5 15C5 20.5228 9.47715 25 15 25C20.5228 25 25 20.5228 25 15C25 9.47715 20.5228 5 15 5ZM2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5C8.09644 27.5 2.5 21.9036 2.5 15ZM15 7.5C15.6904 7.5 16.25 8.05964 16.25 8.75V14.2923L18.7681 15.8031C19.3601 16.1583 19.5521 16.9261 19.1969 17.5181C18.8417 18.1101 18.0739 18.3021 17.4819 17.9469L14.3569 16.0719C13.9804 15.846 13.75 15.4391 13.75 15V8.75C13.75 8.05964 14.3096 7.5 15 7.5Z"
              fill="#60728B"
            />
          </svg>
        ),
        activeKey: "shifts",
      },
      {
        label: "Inbox",
        link: "/inbox",
        badge: unreadNotifications.length + messages.length || "",
        icon: (
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.0049 5C9.48203 5 5.00488 9.47715 5.00488 15C5.00488 16.5991 5.3793 18.1072 6.04411 19.4446C6.40933 20.1793 6.39096 21.0784 6.31359 21.777C6.22969 22.5345 6.03886 23.3417 5.83482 24.0569C5.74058 24.3872 5.64128 24.7056 5.54428 25H15.0049C20.5277 25 25.0049 20.5228 25.0049 15C25.0049 9.47715 20.5277 5 15.0049 5ZM2.60228 25.7684L2.60542 25.7608L2.61945 25.7265C2.6321 25.6953 2.65115 25.6481 2.67546 25.5865C2.72411 25.4634 2.79364 25.2837 2.87508 25.0624C3.03865 24.6181 3.24699 24.0151 3.43074 23.371C3.61645 22.7201 3.76662 22.063 3.82878 21.5018C3.85974 21.2222 3.86576 20.9928 3.85216 20.8157C3.84548 20.7288 3.83477 20.6639 3.82392 20.6182C3.8137 20.5751 3.80529 20.5571 3.80531 20.5571C2.97247 18.8817 2.50488 16.9938 2.50488 15C2.50488 8.09644 8.10132 2.5 15.0049 2.5C21.9084 2.5 27.5049 8.09644 27.5049 15C27.5049 21.9036 21.9084 27.5 15.0049 27.5H3.75589C3.33798 27.5 2.94762 27.2912 2.71578 26.9435C2.48402 26.5959 2.44122 26.1555 2.6017 25.7698L2.60228 25.7684ZM8.75 15C8.75 14.3096 9.30964 13.75 10 13.75H10.0125C10.7029 13.75 11.2625 14.3096 11.2625 15C11.2625 15.6904 10.7029 16.25 10.0125 16.25H10C9.30964 16.25 8.75 15.6904 8.75 15ZM13.75 15C13.75 14.3096 14.3096 13.75 15 13.75H15.0125C15.7029 13.75 16.2625 14.3096 16.2625 15C16.2625 15.6904 15.7029 16.25 15.0125 16.25H15C14.3096 16.25 13.75 15.6904 13.75 15ZM18.75 15C18.75 14.3096 19.3096 13.75 20 13.75H20.0125C20.7029 13.75 21.2625 14.3096 21.2625 15C21.2625 15.6904 20.7029 16.25 20.0125 16.25H20C19.3096 16.25 18.75 15.6904 18.75 15Z"
              fill="#60728B"
            />
          </svg>
        ),
        activeKey: "inbox",
      },
      {
        label: "Profile",
        link: "/profile",
        icon: (
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.4998 5.16732C13.3597 5.16732 11.6248 6.90221 11.6248 9.04232C11.6248 11.1824 13.3597 12.9173 15.4998 12.9173C17.6399 12.9173 19.3748 11.1824 19.3748 9.04232C19.3748 6.90221 17.6399 5.16732 15.4998 5.16732ZM9.0415 9.04232C9.0415 5.47548 11.933 2.58398 15.4998 2.58398C19.0667 2.58398 21.9582 5.47548 21.9582 9.04232C21.9582 12.6092 19.0667 15.5007 15.4998 15.5007C11.933 15.5007 9.0415 12.6092 9.0415 9.04232ZM7.85699 25.834H23.1427C22.5278 22.1686 19.34 19.3757 15.4998 19.3757C11.6597 19.3757 8.47193 22.1686 7.85699 25.834ZM5.1665 27.1257C5.1665 21.4187 9.7929 16.7923 15.4998 16.7923C21.2068 16.7923 25.8332 21.4187 25.8332 27.1257C25.8332 27.839 25.2549 28.4173 24.5415 28.4173H6.45817C5.7448 28.4173 5.1665 27.839 5.1665 27.1257Z"
              fill="#60728B"
            />
          </svg>
        ),
        activeKey: "profile",
      },
    ],
    [unreadNotifications, messages],
  );

  useEffect(() => {
    const path = window.location.pathname;

    setVisible(!excludedPaths.includes(path));
  }, [excludedPaths, location]);

  return visible ? (
    <div className={styles["container"]}>
      <div className={styles["nav-bar"]}>
        {navItems.map((item) => {
          return <NavItem key={item.label} {...item} />;
        })}
      </div>
    </div>
  ) : (
    <></>
  );
};

const NavItem = ({
  icon,
  link = "/",
  hasNotifications,
  label,
  activeKey,
  badge,
}) => {
  const navigate = useNavigate();

  const currentPath = window.location.pathname;

  return (
    <div
      className={clsx(
        styles["nav-item"],
        currentPath === "/" + activeKey && styles["active"],
      )}
      onClick={() => navigate(link)}
    >
      <div className={styles["nav-item-content"]}>
        {badge && <div className={styles["badge"]}>{badge}</div>}
        {icon}
        {hasNotifications && <div className={styles["notification"]}></div>}
        <p className={clsx("footer")}>{label}</p>
      </div>
    </div>
  );
};

export default ShifterNavigation;
