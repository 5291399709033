import { useStyling, useFetch, SideLayout } from "shiftly-ui";

import MultiSelect from "src/pages/search/components/search_bar/shift_filter/Filters/components/MultiSelect";

import styles from "./SFilters.module.css";

import { useEffect, useMemo, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/pro-light-svg-icons";

import { useAutoAnimate } from "@formkit/auto-animate/react";

import { faPlus } from "@fortawesome/pro-solid-svg-icons";

import LabelButton from "src/pages/search/components/search_bar/shift_filter/Filters/components/LabelButton";

const SFWhat = ({ what = {}, setWhat, showFilter }) => {
  const styling = useStyling(styles);

  const [value, setValue] = useState([]);

  const [showAll, setShowAll] = useState(false);

  const [parent] = useAutoAnimate();

  const { data: [{ availableGroups = [] } = []] = [{}] } = useFetch({
    request: {
      entity: "Shift",
      method: "aggregate",
      id: "Position.GetavailableGroupsWithShifts",
      pipeline: [
        {
          $group: {
            _id: "$position",
          },
        },
        {
          $lookup: {
            from: "positions",
            localField: "_id",
            foreignField: "_id",
            as: "positionDetails",
          },
        },
        {
          $project: {
            group: { $arrayElemAt: ["$positionDetails.group", 0] },
          },
        },
        {
          $group: {
            _id: null,
            uniqueGroups: { $addToSet: "$group" },
          },
        },
        {
          $lookup: {
            from: "positiongroups",
            localField: "uniqueGroups",
            foreignField: "_id",
            as: "availableGroups",
          },
        },
        {
          $project: {
            _id: 0,
            availableGroups: 1,
          },
        },
      ],
    },
    options: {
      onSuccess: (data) => {
        setValue(
          what?.ids.map((i) => {
            const group = data.find((group) => group._id === i);

            return { label: group.name, value: group._id };
          }),
        );
      },
    },
  });

  useEffect(() => {
    setValue(
      what?.ids?.map((i) => {
        const group = availableGroups.find((group) => group._id === i);

        return { label: group.name, value: group._id };
      }),
    );
    // eslint-disable-next-line
  }, [showFilter]);

  const filteredGroups = useMemo(
    () =>
      availableGroups.filter(
        (group) => !value.map((i) => i.value).includes(group._id),
      ),
    [availableGroups, value],
  );

  useEffect(() => {
    setWhat({
      ids: value.map((i) => i.value),
      descriptions: value.map((i) => i.label),
    });
  }, [value, setWhat]);

  return (
    <>
      <SideLayout show={showAll} setShow={setShowAll} heading={"All Positions"}>
        <div className={styling("all-groups")}>
          {[...filteredGroups].map((group, idx) => {
            return (
              <LabelButton
                key={idx}
                className={styles["label-button"]}
                icon={faPlus}
                onClick={() => {
                  setValue([...value, { label: group.name, value: group._id }]);
                }}
              >
                {group.name}
              </LabelButton>
            );
          })}
        </div>
      </SideLayout>
      <div className={styling("container")}>
        <MultiSelect
          value={value}
          setValue={setValue}
          label={"Which positions are you looking for?"}
          placeholder={!value.length ? "Select a position" : ""}
          options={availableGroups.map((group) => ({
            label: group.name,
            value: group._id,
          }))}
        />
        <div className={styles["popular-searches"]}>
          <div
            className={styles["pop-heading"]}
            onClick={() => filteredGroups.length > 6 && setShowAll(true)}
          >
            <p className="caption-small">Popular Searches</p>
            {filteredGroups.length > 6 && (
              <p className="caption-small">
                View all{" "}
                <FontAwesomeIcon
                  className={styles["view-all-arrow"]}
                  icon={faArrowRight}
                />
              </p>
            )}
          </div>
          <div className={styles["pop-labels"]} ref={parent}>
            {filteredGroups.length ? (
              [...filteredGroups].splice(0, 6).map((group, idx) => {
                return (
                  <LabelButton
                    key={idx}
                    className={styles["label-button"]}
                    icon={faPlus}
                    onClick={() => {
                      setValue([
                        ...value,
                        { label: group.name, value: group._id },
                      ]);
                    }}
                  >
                    {group.name}
                  </LabelButton>
                );
              })
            ) : (
              <>
                <p className={styling("no-more-positions")}>
                  No more available positions
                  <br />
                  Check back later!
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SFWhat;
