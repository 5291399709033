import {
  SideLayout,
  useStyling,
  useProfile,
  Button,
  Input,
  TextArea,
  newGUID,
  FormV2,
} from "shiftly-ui";

import styles from "./CVEdit.module.css";

import { useMemo, useCallback } from "react";

import { faSave } from "@fortawesome/pro-regular-svg-icons";

const CVEditExperience = ({
  show,
  setShow = () => {},
  record = {},
  mode = "Add",
}) => {
  const styling = useStyling(styles);

  const { profile, updateProfile, updatingProfile, isError } = useProfile();

  const initial = useMemo(() => {
    if (show && mode === "Add")
      return {
        name: "",
        issuing_body: "",
        issue_date: "",
        description: "",
      };

    if (record.id) return record;
  }, [record, show, mode]);

  const handleSubmit = useCallback(
    async (data) => {
      const { work_experience } = structuredClone(profile);

      if (record.id) {
        const index = work_experience.findIndex((exp) => exp.id === record.id);

        work_experience[index] = { ...work_experience[index], ...data };
      } else {
        work_experience.push({ ...data, id: newGUID() });
      }

      const response = await updateProfile({ data: { work_experience } });

      !response.error && setShow(false);

      return response;
    },
    [profile, updateProfile, record, setShow],
  );

  return (
    <SideLayout heading={mode + " Experience"} show={show} setShow={setShow}>
      <FormV2 initial={initial} onSubmit={handleSubmit}>
        <div className={styling("container")}>
          <Input
            name={"position"}
            label={"What did you work as"}
            placeholder={"Barista"}
            required
          />
          <Input
            name={"venue"}
            label={"Where did you work"}
            placeholder={"Cool Cafe"}
            required
          />
          <Input
            type="date"
            name={"start_date"}
            label={"When did you start?"}
            required
            validate={(v) => {
              if (!v) return false;

              return new Date(v) < new Date()
                ? true
                : "Start date must be in the past";
            }}
          />
          <TextArea
            name={"description"}
            label={"What did you do?"}
            required
            placeholder={"Describe some of your duties and skills here"}
          />
          <Button
            loading={updatingProfile}
            error={isError}
            theme={"secondary"}
            type="submit"
            className={styling("save-btn")}
            icon={faSave}
          >
            Save Experience
          </Button>
          <Button
            theme={"secondary-transparent"}
            className={styling("save-btn")}
            onClick={() => {
              setShow(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </FormV2>
    </SideLayout>
  );
};

export default CVEditExperience;
