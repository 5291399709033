import { FileUploader, useProfile } from "shiftly-ui";

import styles from "../ProfileHome.module.css";

import { useRef } from "react";

import Skeleton from "react-loading-skeleton";

import noImage from "shiftly-ui/assets/png/no_img_placeholder.jpeg";

const ProfileHomePicture = ({ picture, isLoading: profileLoading }) => {
  const fileUploaderRef = useRef();

  const { updateProfilePic } = useProfile();

  return (
    <>
      <FileUploader
        visible={false}
        ref={fileUploaderRef}
        onUploadComplete={updateProfilePic}
      />
      <div className={styles["progress-spinner-container"]}>
        <div className={styles["progress-spinner-initial"]}>
          {profileLoading ? (
            <Skeleton
              circle
              height={"150px"}
              width={"150px"}
              className={styles["progress-img-skeleton"]}
            />
          ) : (
            <img
              src={picture === "/defaults/shifter.png" ? noImage : picture}
              alt="user profile"
              onClick={() => fileUploaderRef.current?.click()}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileHomePicture;
