import { useAuth, useUI } from "shiftly-ui";

import { useEffect } from "react";

import styles from "./Main.module.css";

import { useLocation, useNavigate } from "react-router-dom";

import ShifterNavigation from "../UI/ShifterNavigation";

const Main = ({ children }) => {
  const {
    auth: { isAuthenticated },
  } = useAuth();

  const { loading } = useUI();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (loading || pathname === "/portal") return;

    if (!isAuthenticated) {
      navigate("/portal?page=welcome");
    }
  }, [isAuthenticated, navigate, loading, pathname]);

  return (
    <div className={styles["container"]}>
      <ShifterNavigation />
      <div className={styles["content-wrapper"]}>{children}</div>
    </div>
  );
};

export default Main;
