import { useUI } from "shiftly-ui";

import Searchbar from "./components/search_bar/Searchbar";

import ShiftStack from "./components/ShiftStack";

import MapView from "./components/MapView";

import styles from "./Search.module.css";

const Search = () => {
  const {
    shifts: { viewMode },
  } = useUI();

  return (
    <div className={styles["container"]}>
      <Searchbar />
      {viewMode === "scroll" ? <ShiftStack /> : <MapView />}
    </div>
  );
};

export default Search;
