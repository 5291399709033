import { useFetch, shiftActions, useUI, useAuth } from "shiftly-ui";

import { useDispatch, useSelector } from "react-redux";

import { useCallback, useEffect, useState } from "react";

const useShifts = () => {
  const shifts = useSelector((state) => state.shifts);
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [pageOffset, setPageOffset] = useState(0);

  const {
    shifts: { viewMode },
  } = useUI();

  const clearStack = useCallback(
    (data) => {
      dispatch(shiftActions.clearStack(data));
    },
    [dispatch]
  );

  const { post: searchShifts, isLoading } = useFetch({
    options: {
      onSuccess: (data) => {
        dispatch(shiftActions.setPointer(0));

        clearStack({ stack: "leftStack" });
        clearStack({ stack: "rightStack" });
        clearStack({ stack: "scrollStack" });
        dispatch(shiftActions.setLoading(false));
        dispatch(shiftActions.setFilteredShifts(data.shifts));

        if (data.page && data.page !== shifts.page) {
          setPageOffset(Math.max(data.page - shifts.page, 0));
        }
      },
    },
  });

  const handleSearch = useCallback(
    ({ what, where, when, wage, user: givenUsr, page }) => {
      if (!user && !givenUsr) return;

      localStorage.setItem("shiftFilter", JSON.stringify({ what, where, when, wage }));

      searchShifts({
        entity: "Filter",
        method: "applyFilterFromSearch",
        data: {
          filter: {
            what,
            where,
            when,
            wage,
          },
          user: givenUsr || user,
          page: page ?? shifts.page + pageOffset,
          viewMode,
        },
      });
    },
    [searchShifts, user, shifts.page, pageOffset, viewMode]
  );

  useEffect(() => {
    isLoading && dispatch(shiftActions.setLoading(true));
  }, [isLoading, dispatch]);

  useEffect(() => {
    dispatch(shiftActions.setPage(1));
    setPageOffset(0);
  }, [shifts.filter, dispatch]);

  return {
    ...shifts,
    shiftIsLoading: isLoading,
    handleSearch,
    setLoading: useCallback((params) => dispatch(shiftActions.setLoading(params)), [dispatch]),
    setPage: useCallback((params) => dispatch(shiftActions.setPage(params)), [dispatch]),
    resetPage: useCallback(() => {
      dispatch(shiftActions.setPage(1));
      setPageOffset(0);
    }, [dispatch]),
    setFilteredShifts: useCallback((params) => dispatch(shiftActions.setFilteredShifts(params)), [dispatch]),
    setFilter: useCallback((params) => dispatch(shiftActions.setFilter(params)), [dispatch]),
    setPointer: useCallback((params) => dispatch(shiftActions.setPointer(params)), [dispatch]),
    pushToStack: useCallback((params) => dispatch(shiftActions.pushToStack(params)), [dispatch]),
    popFromStack: useCallback((params) => dispatch(shiftActions.popFromStack(params)), [dispatch]),
    clearStack,
  };
};

export default useShifts;
