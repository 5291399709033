import {
  CentralHeader,
  useStyling,
  Button,
  useAlerts,
  Input,
  useFetch,
  useAuth,
  FormV2,
} from "shiftly-ui";

import styles from "./Security.module.css";

import { useCallback } from "react";

const Security = () => {
  const styling = useStyling(styles);

  const { confirm } = useAlerts();

  const { user } = useAuth();

  const {
    post: changePassword,
    isLoading: changingPassword,
    isError,
  } = useFetch({
    options: {
      onSuccess: () => {},
    },
  });

  const { post: deactivateAccount, isLoading: deactivating } = useFetch({
    options: {
      onSuccess: () => {
        window.location.href = "/portal";
      },
    },
  });

  const handleResetPassword = useCallback(
    async (data) => {
      const { current_password, password, confirm_password } = data;

      if (password !== confirm_password) {
        return {
          error: {
            message: "Passwords do not match",
            field: "confirm_password",
          },
        };
      }

      return await changePassword({
        entity: "Authentication",
        method: "changePassword",
        criteria: {
          user: user?._id,
        },
        data: {
          currentPassword: current_password,
          newPassword: password,
        },
      });
    },
    [changePassword, user],
  );

  const handleDeactivateAccount = useCallback(() => {
    deactivateAccount({
      entity: "Authentication",
      method: "deactivateAccount",
      criteria: {
        user: user?._id,
      },
    });
  }, [deactivateAccount, user]);

  return (
    <div className={styling("container")}>
      <CentralHeader text={"Login & Security"} chevron link={"/profile"} />
      <div className={styling("password")}>
        <FormV2 onSubmit={handleResetPassword}>
          <Input name={"current_password"} />
          <Input mt={"40px"} name={"password"} validate={false} />
          <Input name={"confirm_password"} mb={"20px"} />
          <Button
            type={"submit"}
            error={isError}
            loading={changingPassword}
            theme={"secondary"}
          >
            Change Password
          </Button>
        </FormV2>
      </div>
      <div className={styling("deactivate-account")}>
        <h4>Danger zone:</h4>
        <Button
          loading={deactivating}
          onClick={async () => {
            if (
              !(await confirm({
                label: "Deactivate Account",
                text: "Are you sure you want to deactivate your account? This cannot be undone.",
                confirmText: "Goodbye Shiftly",
                cancelText: "Cancel",
                inverse: true,
              }))
            )
              return;

            handleDeactivateAccount();
          }}
        >
          Deactivate Account
        </Button>
      </div>
    </div>
  );
};

export default Security;
