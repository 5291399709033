import { useStyling, BottomLink } from "shiftly-ui";

import { useLocation, useNavigate } from "react-router-dom";

import styles from "./CompletedShift.module.css";

import Illustration from "shiftly-ui/assets/svg/Illustrations/NoShifts.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTimes } from "@fortawesome/pro-solid-svg-icons";

import RatingPopout from "./components/RatingPopout";

const CompletedShift = () => {
  const styling = useStyling(styles);

  const navigate = useNavigate();

  const {
    state: { shift },
  } = useLocation();

  return (
    <>
      <RatingPopout shift={shift} />
      <div className={styling("container")}>
        <div className={styling("heading")}>
          <h3>Today</h3>
        </div>
        <div className={styles["content"]}>
          <FontAwesomeIcon
            icon={faTimes}
            className={styling("close-icon")}
            onClick={() => navigate("/shifts")}
          />
          <div className={styles["illustration"]}>
            <img src={Illustration} alt="Shift Completed" />
          </div>
          <div className={styles["content-text"]}>
            <h3>Shift Completed</h3>
            <p className={styling("strong-text")}>
              Thank you for completing your shift with{" "}
              <span>{shift?.location?.name}</span>. We hope you enjoyed your
              experience.
            </p>
            <p>
              To find out more about wages or date of payment head to the
              payments section below.
            </p>
          </div>
        </div>
        <BottomLink
          links={[
            { name: "Shift History", path: "/shift-history" },
            { name: "Payments", path: "Payments" },
          ]}
        />
      </div>
    </>
  );
};

export default CompletedShift;
