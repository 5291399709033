import { useStyling, Increment } from "shiftly-ui";

import { useEffect, useState } from "react";

import styles from "./SFilters.module.css";

import AddressAutocomplete from "src/pages/search/components/search_bar/shift_filter/Filters/components/AddressAutoComplete";

const SFWhere = ({ where = {}, setWhere }) => {
  const styling = useStyling(styles);

  const [location, setLocation] = useState({});

  useEffect(() => {
    if (location.place_id) {
      const { longitude, latitude, full_address } = location;

      setWhere((prev) => ({
        ...prev,
        longitude,
        latitude,
        fullAddress: full_address,
      }));
    }
  }, [location, setWhere]);

  return (
    <div className={styling("container")}>
      <AddressAutocomplete
        fullAddress={false}
        onChange={setLocation}
        label={"Location"}
        placeholder={'Try "Sydney"...'}
        initialValue={where.fullAddress}
      />
      <div className={styling("increments")}>
        <Increment
          label={"In range"}
          unit={"km"}
          step={5}
          value={where?.range || 0}
          setValue={(range) => setWhere((prev) => ({ ...prev, range }))}
        />
      </div>
    </div>
  );
};

export default SFWhere;
