import { useInlineStyles } from "shiftly-ui";

import { useNavigate } from "react-router-dom";

import styles from "./LabelButton.module.css";

import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";

const LabelButton = ({
  className,
  children,
  theme,
  size,
  onClick,
  icon,
  link,
  type = "button",
  loading = false,
  iconSide = "right",
  ...props
}) => {
  const style = useInlineStyles({ ...props });

  const navigate = useNavigate();

  return (
    <button
      type={type}
      onClick={link ? () => navigate(link) : onClick}
      style={style}
      className={clsx(
        styles["button"],
        styles[theme],
        loading && styles["loading"],
        className,
      )}
      {...props}
    >
      <div className={clsx(styles["content"], icon && styles[iconSide])}>
        <FontAwesomeIcon
          icon={icon}
          className={clsx(
            styles["icon"],
            iconSide === "left" && styles["icon-left"],
          )}
        />
        <p className="caption-large">
          {loading ? (
            <FontAwesomeIcon icon={faEllipsis} beat={loading} />
          ) : (
            children
          )}
        </p>
      </div>
    </button>
  );
};

export default LabelButton;
