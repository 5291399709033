import { BlobSvg } from "shiftly-ui";

import styles from "../ProfileHome.module.css";

import clsx from "clsx";

const ProfileHomeBackdrop = () => {
  return (
    <div className={styles["background"]}>
      <div className={clsx(styles["blob"], styles["blob-1"])}>
        <BlobSvg />
      </div>
      <div className={clsx(styles["blob"], styles["blob-3"])}>
        <BlobSvg />
      </div>
      <div className={styles["background-drop"]}>
        <div className={styles["circle1"]}></div>
        <div className={styles["circle2"]}></div>
      </div>
    </div>
  );
};

export default ProfileHomeBackdrop;
