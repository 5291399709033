import { buildGoogleMapsLink } from "shiftly-ui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./ShiftDetails.module.css";

import { faArrowRight } from "@fortawesome/pro-duotone-svg-icons";

import { faGlobe } from "@fortawesome/pro-regular-svg-icons";

const ShiftDetailsLocation = ({
  logo = "",
  location = {},
  address = {},
  shift = {},
}) => {
  return (
    <div className={styles["location-container"]}>
      <div className={styles["location-header"]}>
        <div className={styles["location-header-title"]}>
          <div className={styles["location-header-logo"]}>
            <img src={logo} alt="Location Logo" />
          </div>
          <h5>{location?.name}</h5>
        </div>
        <FontAwesomeIcon icon={faArrowRight} />
      </div>
      <div className={styles["location-description"]}>
        <p>{location?.description}</p>
      </div>
      <div className={styles["location-address"]}>
        <div
          className={styles["location-address-heading"]}
          onClick={() =>
            window.open(
              buildGoogleMapsLink({
                lat: address.latitude,
                lng: address.longitude,
              }),
              "_blank",
              "noopener noreferrer",
            )
          }
        >
          <div className={styles["location-address-heading-details"]}>
            <h5>Location</h5>
            <p>{address.full_address}</p>
          </div>
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </div>
      <div className={styles["location-socials"]}>
        {location?.instagram && (
          <SocialIcon
            text={"Instagram"}
            logo="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png"
            link={"https://www.instagram.com/" + location?.instagram}
          />
        )}
        {location?.facebook && (
          <SocialIcon
            text={"Facebook"}
            logo="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
            link={"https://www.facebook.com/" + location?.facebook}
          />
        )}
        {location?.website && (
          <SocialIcon text={"Website"} link={location?.website} />
        )}
      </div>
    </div>
  );
};

const SocialIcon = ({ text, logo, link }) => {
  return (
    <div
      className={styles["social-icon"]}
      onClick={() => {
        window.open(link, "_blank", "noopener noreferrer");
      }}
    >
      {logo ? (
        <img src={logo} alt="Social Icon" />
      ) : (
        <div className={styles["icon"]}>
          <FontAwesomeIcon icon={faGlobe} />
        </div>
      )}
      <p>{text}</p>
    </div>
  );
};

export default ShiftDetailsLocation;
