import {
  FooterControl,
  useStyling,
  CentralHeader,
  SideLayout,
  useDelayUnmount,
  truncate,
  formatDateToPrettyDate,
  useFetch,
  useToast,
  useAuth,
} from "shiftly-ui";
import styles from "./ShiftFilter.module.css";
import { motion } from "framer-motion";
import { useUncontrolled } from "uncontrollable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faHeart, faLoader } from "@fortawesome/pro-solid-svg-icons";
import { faMagnifyingGlass, faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { useCallback, useEffect, useMemo } from "react";
import SFWhat from "./Filters/SFWhat";
import SFWhere from "./Filters/SFWhere";
import SFWhen from "./Filters/SFWhen";
import SFWage from "./Filters/SFWage";
import SFAction from "./components/SFAction";
import useShifts from "src/hooks/useShifts";

const variants = {
  open: {
    y: "0%",
  },
  closed: {
    y: "100%",
  },
};

const orderOfTabs = ["What", "Where", "When", "Wage"];

const ShiftFilter = (props) => {
  const styling = useStyling(styles);

  const {
    showFilter,
    setShowFilter,
    what = {
      ids: [],
      descriptions: [],
    },
    setWhat,
    where = {},
    setWhere,
    when = [],
    setWhen,
    wage = [],
    setWage,
    activeTab,
    setActiveTab,
  } = useUncontrolled(props, {
    showFilter: "setShowFilter",
    what: "setWhat",
    where: "setWhere",
    when: "setWhen",
    wage: "setWage",
    activeTab: "setActiveTab",
  });

  const toast = useToast();

  const { handleSearch, setFilter, resetPage } = useShifts();

  const { user } = useAuth();

  const isTouched = useMemo(
    () => what?.ids?.length || where?.fullAddress || when.length || wage,
    [what, where, when, wage]
  );

  const { post: saveFilter, isLoading: saveIsLoading } = useFetch({
    options: {
      onSuccess: () => {
        toast.success("You can retrieve this filter later in the 'Saved' tab.", "Saved");
      },
    },
  });

  useEffect(() => {
    setActiveTab("");
    //eslint-disable-next-line
  }, [showFilter]);

  useEffect(() => {
    const cachedFilter = localStorage.getItem("shiftFilter");

    if (cachedFilter) {
      const { what, where, when, wage } = JSON.parse(cachedFilter);

      setWhat(what)
      setWhere(where);
      setWhen(when);
      setWage(wage);

      handleSearch({ what, where, when, wage, user, page: 1 });
    } else {
      handleSearch({
        what: {
          ids: [],
          descriptions: [],
        },
        where: {},
        when: [],
        wage: 0,
        user,
        page: 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setWhat, setWhere, setWhen, setWage, user]);

  const handleNextClick = useCallback(() => {
    const currentIndex = orderOfTabs.indexOf(activeTab);

    const nextTab = orderOfTabs[currentIndex + 1];

    setActiveTab(nextTab);
  }, [activeTab, setActiveTab]);

  const handlePrevClick = useCallback(() => {
    const currentIndex = orderOfTabs.indexOf(activeTab);

    const prevTab = orderOfTabs[currentIndex - 1];

    setActiveTab(prevTab);
  }, [activeTab, setActiveTab]);

  const handleSaveFilter = useCallback(() => {
    saveFilter({
      entity: "Filter",
      method: "create",
      data: {
        user: user._id,
        what,
        where,
        when,
        wage,
      },
    });
  }, [what, where, when, wage, saveFilter, user]);

  const handleRefresh = useCallback(() => {
    if (!isTouched) return;

    resetPage();

    setFilter({
      what: {
        descriptions: [],
        ids: [],
      },
      where: {},
      when: [],
      wage: 0,
    });

    localStorage.removeItem("shiftFilter");
  }, [setFilter, isTouched, resetPage]);

  return (
    <motion.div
      variants={variants}
      initial={"closed"}
      animate={showFilter ? "open" : "closed"}
      transition={{ duration: 0.3 }}
      className={styling("container")}
    >
      <div className={styling("wrapper")}>
        <CentralHeader text={"Filter your search"} close setShow={setShowFilter} />
        <div className={styling("filter-types")}>
          <FilterType
            theW={"What"}
            text={"are you looking for?"}
            value={what?.descriptions?.length ? what.descriptions.join(", ") : "positions"}
            show={activeTab === "What"}
            setShow={setActiveTab}
          >
            <SFWhat what={what} setWhat={setWhat} showFilter={activeTab === "What"} />
          </FilterType>
          <FilterType
            theW={"Where"}
            text={"are you heading?"}
            value={where?.fullAddress ?? "location & range"}
            show={activeTab === "Where"}
            setShow={setActiveTab}
          >
            <SFWhere where={where} setWhere={setWhere} />
          </FilterType>
          <FilterType
            theW={"When"}
            text={"are you available?"}
            value={when.length ? when.map((d) => formatDateToPrettyDate(d)).join(" - ") : "dates"}
            show={activeTab === "When"}
            setShow={setActiveTab}
          >
            <SFWhen when={when} setWhen={setWhen} />
          </FilterType>
          <FilterType
            theW={"Wage"}
            text={"expectations?"}
            value={wage ? "Min. $" + wage + "/hr" : "dollars"}
            show={activeTab === "Wage"}
            setShow={setActiveTab}
          >
            <SFWage wage={wage} setWage={setWage} />
          </FilterType>
        </div>
        <div className={styling("filter-actions")}>
          <SFAction text={"Refresh"} icon={faRefresh} onClick={handleRefresh} isTouched={isTouched} />
          <SFAction
            text={"Save"}
            icon={saveIsLoading ? faLoader : faHeart}
            spin={saveIsLoading}
            onClick={handleSaveFilter}
            isTouched={isTouched}
          />
        </div>
      </div>
      <FooterControl
        className={styling("footer-control")}
        buttonText="Search"
        nextButton={<p className={styling("control-btn")}>Next</p>}
        prevButton={<p className={styling("control-btn")}>Back</p>}
        prevButtonDisabled={!activeTab}
        nextButtonDisabled={!activeTab}
        onButtonClick={() => {
          resetPage();

          handleSearch({ what, where, when, wage, page: 1 });

          setShowFilter(false);
        }}
        onNextClick={handleNextClick}
        onPrevClick={handlePrevClick}
        buttonProps={{
          icon: faMagnifyingGlass,
        }}
      />
    </motion.div>
  );
};

const FilterType = ({ text, value, theW, children, show, setShow }) => {
  const styling = useStyling(styles);

  const isVisible = useDelayUnmount(show, 200);

  return (
    <>
      <SideLayout show={isVisible} setShow={setShow} heading={`${theW} ${text}`}>
        {children}
      </SideLayout>
      <div className={styling("filter-type")} onClick={() => setShow(theW)}>
        <p className={styling("filter-text")}>
          <span>{theW}</span> {text}
        </p>
        <p className={styling("filter-value")}>{truncate(value, 20)}</p>
        <FontAwesomeIcon icon={faChevronRight} className={styling("filter-chevron")} />
      </div>
    </>
  );
};

export default ShiftFilter;
